export enum QUERY_KEYS {
	GET_PLANS = 'get-plans',
	GET_PLAN = 'get-plan',
	GET_FILE = 'get-file',
	GET_USERS = 'get-users',
	GET_SECTORS = 'get-sectors',
	GET_CELLULES = 'get-cellules',
	GET_COMPANIES = 'get-companies',
	GET_EVIDENCES = 'get-evidences',
	GET_FILES_LIST = 'get-file-list',
	GET_ACTIVITIES = 'get-activities',
	GET_ATTACHMENTS = 'get-attachments',
	GET_ACTION_PLAN = 'get-action-plan',
	GET_RISK_RANGES = 'get-risk-ranges',
	GET_REBA_REPORT = 'get-reba-report',
	GET_ANSIZ_REPORT = 'get-ansiz-report',
	GET_ANSIZ_SCORE = 'get-ansiz-score',
	GET_RULA_REPORT = 'get-rula-report',
	GET_ANSIZ_IMAGE = 'get-ansiz-image',
	GET_NIOSH_REPORT = 'get-niosh-report',
	GET_COMPANY_USERS = 'get-company-users',
	GET_PERMISSIONS = 'get-all-permissions',
	GET_KIM_PP_REPORT = 'get-kim-pp-report',
	GET_THUMBNAIL_URL = 'get_thumbnail_url',
	GET_KIM_MHO_REPORT = 'get-kim-mho-report',
	UPDATE_ANSIZ_IMAGE = 'update-ansiz-image',
	DELETE_ANSIZ_IMAGE = 'delete-ansiz-image',
	GET_FILE_HIERARCHY = 'get-gile-hierarchy',
	GET_RELATED_REPORTS = 'get-related-reports',
	GET_CELLULE_OPTIONS = 'get-cellule-options',
	GET_ACTIVITY_OPTIONS = 'get-activity-options',
	GET_USER_PERMISSIONS = 'get-user-permissions',
	GET_ANSIZ_ACTION_PLAN = 'get-ansiz-action-plan',
	GET_ANSIZ_FACTOR_SCORE = 'get-ansiz-factor-score',
	GET_ANSIZ_REPORT_STEPS = 'get-ansiz-report-steps',
	GET_ANSIZ_REPORT_STEP_VALUES = 'get-ansiz-report-step-values',
	CREATE_ANSIZ_URL_SIGNED = 'create-ansiz-url-signed',
	GET_STRAIN_INDEX_REPORT = 'get-strain-index-report',
	GET_ANSIZ_REPORT_ANGLES = 'get-ansiz-report-angles',
	GET_ACTION_PLAN_LIST = 'get-action-plan-list',
	GET_ACTION_PLAN_TASKS = 'get-action-plan-tasks',
	GET_ACTION_PLANS_COUNT = 'get-action-plans-count',
	GET_ACTION_PLAN_HISTORY = 'get-action-plan-history',
	GET_ACTION_PLAN_ORIGINS = 'get-action-plan-origins',
	GET_ACTION_PLAN_COMMENTS = 'get-action-plan-comments',
	GET_RESPONSIBLES_OPTIONS = 'get-responsibles-options',
	GET_PLANS_CONTRACT_PLANS = 'get-plans-contract-plans',
	GET_WORKSTATIONS_OPTIONS = 'get-workstations-options',
	GET_ACTION_PLAN_CARD_LIST = 'get-action-plan-card-list',
	GET_CUSTOM_REPORT_OPTIONS = 'get-custom-report-options',
	GET_COMPANY_CONTRACT_PLANS = 'get-company-contract-plans',
	GET_REBA_REPORTS_PAGINATED = 'get-reba-reports-paginated',
	GET_ANSIZ_REPORTS_PAGINATED = 'get-ansiz-reports-paginated',
	GET_ACTION_PLANS_BY_REPORT = 'get-action-plans-by-report',
	GET_ROSA_REPORTS_PAGINATED = 'get-rosa-reports-paginated',
	GET_OWAS_REPORTS_PAGINATED = 'get-owas-reports-paginated',
	GET_RULA_REPORTS_PAGINATED = 'get-rula-reports-paginated',
	GET_NIOSH_REPORTS_PAGINATED = 'get-niosh-reports-paginated',
	GET_ATTACHMENT_DOWNLOAD_URL = 'get-attachment-download-url',
	GET_ACTION_PLAN_FROM_REPORT = 'get-action-plan-from-report',
	GET_COMPANIES_CONTRACT_PLANS = 'get-companies-contract-plans',
	GET_KIM_MHO_REPORTS_PAGINATED = 'get-kim-pp-reports-paginated',
	GET_KIM_PP_REPORTS_PAGINATED = 'get-kim-mho-reports-paginated',
	GET_ACTION_PLAN_NOTIFICATIONS = 'get-action-plan-notifications',
	GET_COMPANIES_WITH_ORGANIZATION = 'get-company-with-organization',
	GET_ANSIZ_REPORT_ANGLES_DOUGHNUT = 'get-ansiz-report-angles-doughnut',
	GET_TASK_ATTACHMENT_DOWNLOAD_URL = 'get-task-attachment-download-url',
	GET_ANGLE_TIME_REPORTS_PAGINATED = 'get-angle-time-reports-paginated',
	GET_ORGANIZATIONS_CONTRACT_PLANS = 'get-organizations-contract-plans',
	GET_CUSTOM_REPORT_RESULT_PAGINATED = 'get-custom-report-result-paginated',
	GET_STRAIN_INDEX_REPORTS_PAGINATED = 'get-strain-index-reports-paginated',
	GET_LIBERTY_MUTUAL_REPORTS_PAGINATED = 'get-liberty-mutual-reports-paginated',
	GET_ORGANIZATION_USERS_CONTRACT_PLANS = 'get-organization-users-contract-plans',
	GET_PRELIMINARY_ANALYZES_RESULT_PAGINATED = 'get-preliminary-analyzes-result-paginated',
	GET_MOSAIC = 'get-mosaic',
	GET_STATUS_ACTION_PLANS = 'get-status-action-plans',
	GET_CUSTOM_REPORT_WORST_RISK = 'get-custom-report-worst-risk',
	GET_CUSTOM_REPORT_WORST_SCORES = 'get-custom-report-worst-scores',
	GET_CUSTOM_REPORT_EVOLUTION = 'get-custom-report-evolution',
	GET_CUSTOM_REPORT_RISK_EVOLUTION = 'get-custom-report-risk-evolution',
	GET_CUSTOM_REPORT_COUNT_HIGHER_RISK_FOUND = 'get-custom-report-count-higher-risk-found',
	GET_REBA_COUNT_HIGHER_RISK_FOUND = 'get-reba-count-higher-risk-found',
	GET_REBA_BODY_SIDE_EXPOSURE = 'get-reba-body-side-exposure',
	GET_REBA_BODY_PARTS_EXPOSURE = 'get-reba-body-parts-exposure',
	GET_KIM_PP_COUNT_HIGHER_RISK_FOUND = 'get-kim-pp-count-higher-risk-found',
	GET_NIOSH_COUNT_HIGHER_RISK_FOUND = 'get-niosh-count-higher-risk-found',
	GET_RULA_COUNT_HIGHER_RISK_FOUND = 'get-rula-count-higher-risk-found',
	GET_KIM_MHO_COUNT_HIGHER_RISK_FOUND = 'get-kim-mho-count-higher-risk-found',
	GET_WORKSTATIONS_COUNT = 'get-workstation-count',
	GET_STRAIN_INDEX_RISK = 'get-strain-index-risk',
	GET_LIBERTY_MUTUAL_MOVEMENT_INCIDENCE = 'get-liberty-mutual-movement-incidence',
	GET_ACTION_PLAN_USER_PREFERENCE_COLUMNS = 'get-action-plan-user-preference-columns'
}
