import { FileStatus } from '@/types';
import { I18n } from '@aws-amplify/core';

export const typesExportPDF = [
	{
		id: 1,
		label: 'Análise do Movimento Ergonomia',
		value: '999'
	},
	{
		id: 2,
		label: 'Relatório por Ângulo',
		value: '666'
	}
];

export const ENUM_TYPE_PLAN = {
	test: 'Test',
	team: 'Team',
	business: 'Business',
	individual: 'Individual',
	pilot: 'Pilot',
	Premium: 'Premium',
	Teleworld: 'Teleworld'
};

export const ACTION_PLAN_DATE_STATUS = [
	{ value: 'ON_TIME', description: 'On time' },
	{ value: 'OVERDUE', description: 'Overdue' },
	{ value: 'THIS_WEEK', description: 'Finishing in a week' }
];

export const ACTION_PLAN_DEADLINE = [
	{ value: 'ASC', description: 'Ascending' },
	{ value: 'DESC', description: 'Descending' }
];

export type FileStatusMapper = {
	[key in FileStatus]: string;
};

export const fileStatusMapper: FileStatusMapper = {
	CORRUPTED_FILE: 'Corrupted file',
	EXTRACTED_DATA: 'Extracted data',
	NOT_PROCESSED: 'Not processing',
	PROCESSING: 'Processing',
	PROCESSED: 'Processed',
	IN_QUEUE: 'In queue'
};

export const bodyParts = {
	trunk: I18n.get('Trunk'),
	neck: I18n.get('Neck'),
	left_lower_arm: I18n.get('Left lower arm'),
	right_lower_arm: I18n.get('Right lower arm'),
	left_upper_arm: I18n.get('Left upper arm'),
	right_upper_arm: I18n.get('Right upper arm')
};

export const statusTypeDownload = {
	NOT_PROCESSED: true,
	PROCESSING: true,
	EXTRACTED_DATA: true
};

export const statusTypeReports = {
	PROCESSING: true,
	NOT_PROCESSED: true
};

// Deprecated
export const roleEnum = {
	USER: 'USER',
	ADMIN: 'ADMIN',
	MASTER: 'MASTER',
	SUPERVISOR: 'SUPERVISOR'
};

export const states = [
	{ value: 'AC', label: 'Acre' },
	{ value: 'AL', label: 'Alagoas' },
	{ value: 'AP', label: 'Amapá' },
	{ value: 'AM', label: 'Amazonas' },
	{ value: 'BA', label: 'Bahia' },
	{ value: 'CE', label: 'Ceará' },
	{ value: 'DF', label: 'Distrito Federal' },
	{ value: 'ES', label: 'Espírito Santo' },
	{ value: 'GO', label: 'Goías' },
	{ value: 'MA', label: 'Maranhão' },
	{ value: 'MT', label: 'Mato Grosso' },
	{ value: 'MS', label: 'Mato Grosso do Sul' },
	{ value: 'MG', label: 'Minas Gerais' },
	{ value: 'PA', label: 'Pará' },
	{ value: 'PB', label: 'Paraíba' },
	{ value: 'PR', label: 'Paraná' },
	{ value: 'PE', label: 'Pernambuco' },
	{ value: 'PI', label: 'Piauí' },
	{ value: 'RJ', label: 'Rio de Janeiro' },
	{ value: 'RN', label: 'Rio Grande do Norte' },
	{ value: 'RS', label: 'Rio Grande do Sul' },
	{ value: 'RO', label: 'Rondônia' },
	{ value: 'RR', label: 'Roraíma' },
	{ value: 'SC', label: 'Santa Catarina' },
	{ value: 'SP', label: 'São Paulo' },
	{ value: 'SE', label: 'Sergipe' },
	{ value: 'TO', label: 'Tocantins' }
];

export const ACTION_PLAN_BOARDS = [
	{ id: 'to_do_key', name: 'To Do', type: 'TO DO', bg_color: '#E9ECEF' },
	{ id: 'doing_key', name: 'Doing', type: 'DOING', bg_color: '#E8EEF5' },
	{ id: 'done_key', name: 'Done', type: 'DONE', bg_color: '#EBF3EE' }
];

export enum ACTION_PLANS_MAPPER {
	TO_DO = 'TO DO',
	DOING = 'DOING',
	DONE = 'DONE'
}

export enum ACTION_PLANS_TYPES {
	TO_DO = 'to_do',
	DOING = 'doing',
	DONE = 'done'
}

export enum FILTER_PARAMS {
	DESC = 'DESC',
	ASC = 'ASC'
}

export enum FILTER_SORT_TYPE {
	DEADLINE_DATE = 'deadline_date',
	DEADLINE = 'deadline',
	SCORE = 'score',
	LEXO_RANK = 'lexo_rank'
}

export enum PROFILE_DEFAULT {
	URL = 'https://kinebot-statics.s3.amazonaws.com/kinebot_default.png'
}

export enum LANGUAGE {
	EN = 'en',
	PT = 'pt',
	ES = 'es'
}

export enum RoleUserEnum {
	ADMIN = 'ADMIN',
	MASTER = 'MASTER',
	SUPERVISOR = 'SUPERVISOR',
	USER = 'USER'
}

export enum EnvironmentEnum {
	PRODUCTION = 'production',
	HOMOLOG = 'homolog',
	STAGE = 'stage',
	DEVELOPMENT = 'development'
}

export const bodyPartsEnum = [
	{
		id: 1,
		name: 'Neck',
		value: 'neck'
	},
	{
		id: 2,
		name: 'Trunk',
		value: 'trunk'
	},
	{
		id: 3,
		name: 'Left shoulder',
		value: 'left_upper_arm'
	},
	{
		id: 4,
		name: 'Right shoulder',
		value: 'right_upper_arm'
	},
	{
		id: 5,
		name: 'Left elbow',
		value: 'left_lower_arm'
	},
	{
		id: 6,
		name: 'Right elbow',
		value: 'right_lower_arm'
	},
	{
		id: 7,
		name: 'Left knee',
		value: 'left_knee'
	},
	{
		id: 8,
		name: 'Right knee',
		value: 'right_knee'
	},
	{
		id: 9,
		name: 'Hip',
		value: 'hip'
	}
];

export enum RoleUserKey {
	USER = 'USER',
	MASTER = 'MASTER',
	SUPERVISOR = 'SUPERVISOR'
}

export enum RoleUserValue {
	USER = 'User',
	MASTER = 'Master',
	SUPERVISOR = 'Supervisor'
}

export enum StatusUserKey {
	ACTIVE = 'active',
	INACTIVE = 'inactive',
	ALL = 'all'
}

export enum StatusUserValue {
	ACTIVE = 'Active',
	INACTIVE = 'Inactive',
	ALL = 'All'
}

export enum ActionPlanPriorityEnum {
	Low = 1,
	Medium,
	High
}
