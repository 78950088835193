export type RiskLevel = {
	[key: number]: string;
};

export const riskLevels: RiskLevel = {
	1: 'Acceptable risk',
	2: 'Moderate risk',
	3: 'High Risk',
	4: 'Very high risk',
	5: 'Serious and imminent risk'
};

export enum RiskMap {
	LOW = 1,
	MODERATE = 2,
	HIGH = 3,
	VERY_HIGH = 4,
	EXTREME = 5
}

export const riskCalc = (score: number) => {
	let result = 1;

	if (score >= 20 && score <= 69) {
		result = 2;
	}

	if (score >= 70 && score <= 199) {
		result = 3;
	}

	if (score >= 200 && score <= 400) {
		result = 4;
	}

	if (score >= 401 && score <= 10000) {
		result = 5;
	}

	return result;
};

export enum CustomReportRiskResults {
	LOW = 1,
	MODERATE = 2,
	HIGH = 3,
	VERY_HIGH = 4,
	EXTREME = 5
}

export const customReportPaletteMap: Record<number, string> = {
	[CustomReportRiskResults.LOW]: '#2CC852',
	[CustomReportRiskResults.MODERATE]: '#FFDE31',
	[CustomReportRiskResults.HIGH]: '#F78A38',
	[CustomReportRiskResults.VERY_HIGH]: '#E74150',
	[CustomReportRiskResults.EXTREME]: '#9B54E2'
};

export function rosaPaletteMap(score: number): string {
	return score > 5 ? '#E74150' : '#2CC852';
}
