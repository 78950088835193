interface Person {
	name: string;
	url_logo: string | null;
}

export type Priority = 'Low' | 'Medium' | 'High' | 'Unknown';

interface ActionPlanItemAttributes {
	id: string;
	title: string;
	status: string;
	result: number;
	responsible: Person;
	score: number;
	dueDate: string | null;
	completedAt: string | null;
	priority: Priority;
	investment: number;
	attachments: number;
	completedTasks: number;
	totalTasks: number;
	progress: number;
	evidences: number;
	author: Person;
	origin: string;
	originTableName: string;
	createdAt: string | null;
	company_id: string;
}

export class ActionPlanList {
	public readonly id: string;
	public readonly company_id: string;
	public readonly title: string;
	public readonly status: string;
	public readonly score: number;
	public readonly priority: Priority;
	public readonly investment: number;
	public readonly progress: number;
	public readonly attachments: number;
	public readonly evidences: number;
	public readonly origin: string;
	public readonly originTableName: string;
	public readonly completedTasks: number;
	public readonly totalTasks: number;
	public readonly responsible: Person;
	public readonly author: Person;
	public readonly dueDate: string | null;
	public readonly completedAt: string | null;
	public readonly createdAt: string | null;
	public readonly result: number;

	constructor({
		id,
		company_id,
		title,
		status,
		score,
		priority,
		investment,
		progress,
		attachments,
		evidences,
		origin,
		originTableName,
		completedTasks,
		totalTasks,
		responsible,
		author,
		dueDate,
		result,
		completedAt,
		createdAt
	}: ActionPlanItemAttributes) {
		this.id = id;
		this.company_id = company_id;
		this.title = title;
		this.status = status;
		this.score = score;
		this.priority = priority;
		this.investment = investment;
		this.progress = progress;
		this.attachments = attachments;
		this.evidences = evidences;
		this.origin = origin;
		this.originTableName = originTableName;
		this.completedTasks = completedTasks;
		this.totalTasks = totalTasks;
		this.responsible = responsible;
		this.author = author;
		this.dueDate = dueDate;
		this.completedAt = completedAt;
		this.createdAt = createdAt;
		this.result = result;
	}
}

export interface ActionPlanListDomain {
	offset: number;
	limit: number;
	total: number;
	data: ActionPlanList[];
}
