import styled from 'styled-components';
import { RiskMap } from '@/utils/riskRange';

interface ScoreCardProps {
	$result: number;
}

const paletteMap: any = {
	[RiskMap.LOW]: '#2CC852',
	[RiskMap.MODERATE]: '#FFDE31',
	[RiskMap.HIGH]: '#F78A38',
	[RiskMap.VERY_HIGH]: '#E74150',
	[RiskMap.EXTREME]: '#9B54E2'
};

export const RiskContainer = styled.div<ScoreCardProps>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	height: 2.4rem;
	font-weight: 600;
	font-size: 1.2rem;
	border-radius: 0.5rem;
	background-color: ${({ $result }) => paletteMap[$result]};
`;

export const RiskLabel = styled.div`
	display: flex;
	align-items: center;
	height: 2rem;
	font-size: 1.1rem;
	margin-bottom: 0.5rem;
`;
